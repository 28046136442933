
import { defineComponent, onMounted } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";

export default defineComponent({
  name: "ProfileOverview",
  props: {
    profile: Object
  },
  setup(props) {
    onMounted(() => {
      if (props.profile?.myself) {
        setCurrentPageBreadcrumbs("accountOverview", ["profile"]);
      } else {
        setCurrentPageBreadcrumbs("accountOverview", ["user", "iprofile"]);
      }
    });
  }
});
